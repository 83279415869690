import { render, staticRenderFns } from "./SubRoleSelect.vue?vue&type=template&id=560e7077&scoped=true&"
import script from "./SubRoleSelect.vue?vue&type=script&lang=ts&"
export * from "./SubRoleSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560e7077",
  null
  
)

export default component.exports