


















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import RolesModule from '@/store/modules/roles/RolesModule';

import ContextMenuSpawnerHover from '@/components/context-menu/ContextMenuSpawnerHover.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import Button from '@/components/ui/button';

import levenshteinFilter from '@/utils/levenshteinFilter';
import { Specialisations } from '@/typings/domain';

@Component({
  components: {
    ContextMenuSpawnerHover,
    ContextMenuLink,
    ContextMenuHeader,
    Button,
  },
})
export default class SubRoleSelect extends Vue {
  public search = '';

  @Prop({ required: true }) roleId: number;
  public closeChild = false;

  get role() {
    return RolesModule.roles.find(role => role.id === this.roleId);
  }

  get subRoles() {
    return this.role.specialisations;
  }

  get hasExactMatch() {
    return this.subRoles.map(role => role.name.toLowerCase()).includes(this.search.trim().toLowerCase());
  }
  get filtered() {
    return levenshteinFilter(this.subRoles, this.search);
  }

  async createSpecialization(name: string) {
    const specialization = await RolesModule.createSpecialization({ parentId: this.role.id, name });
    this.select(specialization.id);
  }

  @Emit('select')
  select(specialisation: Specialisations) {
    return specialisation;
  }
}
